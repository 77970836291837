import { useRef } from "react";
import { ResizeObserver } from "@juggle/resize-observer";

import { getAspectWidthAndHeight } from "@/client/lib/helpers";
import { Collection } from "@/shared/types/collection";
import { getBasePath } from "@/shared/config";
import useMeasure from "react-use-measure";

export function CollectionOverviewTokenDisplay({
  collection,
}: {
  collection: Collection;
}) {
  const imagePlaceholder = `${getBasePath()}/image-placeholder.png`;

  const isLiveView =
    collection.featuredTokenGeneratorUrl &&
    collection.primaryRenderType === "live";

  return (
    <div className="relative w-full h-96 bg-neutral-100 dark:bg-neutral-800">
      {isLiveView ? (
        <GeneratorIframe
          aspectRatio={collection.aspectRatio}
          generatorUrl={collection.featuredTokenGeneratorUrl as string}
        />
      ) : (
        <img
          src={collection.imageUrl ?? imagePlaceholder}
          alt="featured token"
          sizes={"(min-width: 640px) 30vw, 100vw"}
          className="object-contain w-full h-full"
        />
      )}
    </div>
  );
}

//TODO: refactor this to a separate component or use the ArtBlocksGenerator component
const GeneratorIframe = ({
  aspectRatio,
  generatorUrl,
}: {
  aspectRatio?: string;
  generatorUrl: string;
}) => {
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const [frameRef, frameBounds] = useMeasure({
    polyfill: ResizeObserver,
  });
  const [finalWidth, finalHeight] = getAspectWidthAndHeight(
    frameBounds,
    aspectRatio ? Number(aspectRatio) : 1,
    false,
    true
  );

  return (
    <div
      ref={frameRef}
      className="w-full h-full flex justify-center items-center"
    >
      <iframe
        ref={iframeRef}
        src={generatorUrl}
        sandbox="allow-scripts allow-downloads"
        allow="xr-spatial-tracking"
        style={{
          width: finalWidth,
          height: finalHeight,
          overflow: "hidden",
        }}
      />
    </div>
  );
};
